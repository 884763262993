.filenameValid {
  color: blue;
  cursor: pointer;
  text-decoration: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.filenameValid:hover {
  text-decoration: underline;
}

.DeleteBtn {
  opacity: 60%;
  cursor: pointer;
}
.DeleteBtn:hover {
  opacity: 100%;
}