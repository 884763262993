
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.CheckBoxFormationActif {
  font-weight: bold;

}
.PointerDisable {
  cursor: not-allowed;
}