.progresses{
  display: flex;
  align-items: center;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.line{

  width: 120px;
  height: 6px;
  background: #05bfa1;
}
.lineInProgress{

  width: 120px;
  height: 6px;
  background: #062737;
}
.linespace {
  width: 70px;
}
.steps {
  text-align: center;
  display: flex;
  width: 90px;
  height: 40px;
  align-items: center;
  justify-content: center;
}
.steps--pending {
  color: #062737;
}
.steps--valid {
  color: #059882;
}

.LinkActif {
  cursor: pointer;
}

.stepsIco {
  display: flex;
  background-color: #05bfa1;
  color: #fff;
  font-size: 14px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
}

.stepsInProgress{
  display: flex;
  background-color: #062737;
  color: #fff;
  font-size: 14px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
}
